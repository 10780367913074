/* @media(max-width: 760px){ */
    .main-container-splash-screen{
        display: flex;
        height: 100vh;
        width: 100vw;
        background-color: #0097b2;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .logo-container-splash-screen img{
        display: flex;
        width: 100px;
        height: 100px;
    }

    .logo-container-splash-screen{
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        font-size: 60px;
        font-weight: bold;
        height: 80vh;
        
    }

    .logo-container-splash-screen p {
        padding: 20px;
        /* border: 1px solid black;
        border-radius: 20px;
        width: 300px;
        justify-content: center;
        align-items: center;
        text-align: center; */
    }

    .footer-splash-screen{
        display: flex;
        align-items: end;
        height: 20vh;
    }

    .footer-splash-screen img{
        display: flex;
        width: 100px;
        height: 100px;
    }
/* } */