@tailwind base;
@tailwind components;
@tailwind utilities;
.hide{
    display: flex;
    display: none;
}

.loading-container{
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
}