.card-element {
    padding: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    /* width: 80vw; */
  }

  .applePay{
    width: 80vw;
  }

  .payment-form{
    max-width: 100vw;
  margin: 0 auto;
  }